<template lang="pug">
include ../../../../configs/template
form.row
  div(v-if="isCheckDocument").col-12
    +select-validation('body.additional_info.responsibility_work_book','listResponsibility','responsibility','"name_eng"','["required"]')
  div(v-if="isCheckDocument").col-12
    +field-validation('body.additional_info.place_work', 'workPlace', '["required"]')
  div.col-sm-12.col-md-4
    +date-picker-validation('body.additional_info.date_start', 'periodStart', 'dateStartObject', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(:readonly="isCheckDocument" :max="new Date().toISOString()")
  div.col-sm-12.col-md-4
    +date-picker-validation('body.additional_info.date_end', 'periodEnd', 'dateEndObject', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(:readonly="isCheckDocument" :max="new Date().toISOString()")
  div.col-sm-12.col-md-1.d-flex.justify-content-center.align-items-center
    label {{ $t('or') }}
  div.col-sm-12.col-md-3
    +field-validation('body.additional_info.days_work', 'totalDays', '["required", "minValue"]')(:readonly="!!body.additional_info.date_start || !!body.additional_info.date_end")
  div(v-if="isCheckDocument").text-left.col-12
    +switch('body.additional_info.book_registration_practical','present','missingFemale', 'bookPractical')
</template>
<script>
import { verificationISC } from '@/mixins/validationRules'
import { mapState } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
const initBody = () => (
  {
    additional_info: {
      responsibility_work_book: null,
      book_registration_practical: false,
      place_work: null,
      date_start: null,
      date_end: null,
      days_work: null,
      status_line: STATUSES_DOCUMENT_CONSTANTS.draft
    }
  }
)
export default {
  props: { isCheckDocument: { type: Boolean, default: false } },
  data () {
    return {
      body: initBody()
    }
  },
  validations () { return verificationISC.employmentHistory(this) },
  computed: {
    ...mapState({
      listResponsibility: state => state.directory.responsibilityWorkBook
    }),
    dateStartObject () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateEndObject () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    }
  }
}
</script>
